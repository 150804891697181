import { add, formatISO } from 'date-fns'
import { format } from 'date-fns-tz'
import { isInteger, isDate, padStart, trimEnd } from 'lodash'

import deltaOptions from './delta_options'

function _getClientTimezone() {
  // get IANA timezone string for the client
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

function getAbbreviatedTimezone(timeZoneName) {
  if (!timeZoneName) {
    return ''
  }

  return new Date()
    .toLocaleString('en-US', {
      timeZone: timeZoneName,
      timeZoneName: 'short',
    })
    .split(' ')[3]
}

function formatDelta(delta) {
  if (!delta) {
    return delta
  }

  if (Object.keys(delta).length !== 1) {
    throw new Error(`Expected delta object to have one key, got ${delta}`)
  }

  let unit = Object.keys(delta)[0]
  const magnitude = Object.values(delta)[0]

  if (Math.abs(magnitude) === 1) {
    unit = trimEnd(unit, 's')
  }

  if (magnitude === 0) {
    return 'Same Day'
  }

  return magnitude < 0
    ? `${Math.abs(magnitude)} ${unit} before`
    : `${magnitude} ${unit} after`
}

function formatTime(time, { timeZone } = {}) {
  if (!time) {
    return time
  }

  return `${time.hour % 12 || 12}:${padStart(time.minute, 2, '0')} ${
    time.hour >= 12 ? 'PM' : 'AM'
  } ${getAbbreviatedTimezone(timeZone)}`
}

function formatDate(date, showYear = false) {
  if (date === null || date === undefined) {
    return date
  }

  if (!isDate(date)) {
    throw new TypeError(`Got "${date}" for date, expected a Date object`)
  }

  return showYear ? format(date, 'MMMM d, yyyy') : format(date, 'MMMM d')
}

function formatDynamicDate(delta, time, timezone, absoluteDate = null) {
  if (absoluteDate !== null) {
    if (!isDate(absoluteDate)) {
      throw new TypeError(
        `Got "${absoluteDate}" for absoluteDate, expected a Date object or null`
      )
    }

    if (!time) {
      return formatDate(add(absoluteDate, delta))
    }

    absoluteDate.setHours(time.hour)
    absoluteDate.setMinutes(time.minute)

    return `${formatDate(add(absoluteDate, delta))} at ${format(
      absoluteDate,
      'h:mm a zzz',
      { timeZone: timezone }
    )}`
  }

  // we need a specific date to determine hours and the abbreviated timezone, the current date is a natural default
  const baseDate = new Date()

  if (!time) {
    return formatDelta(delta)
  }

  baseDate.setHours(time.hour)
  baseDate.setMinutes(time.minute)

  return `${formatDelta(delta)} at ${format(baseDate, 'h:mm a zzz', {
    timeZone: timezone,
  })}`
}

function formatDatetime(datetime) {
  if (datetime === null || datetime === undefined) {
    return datetime
  }

  if (!isDate(datetime)) {
    throw new TypeError(
      `Got "${datetime}" for datetime, expected a Date object`
    )
  }

  return format(datetime, 'MMMM do, yyyy h:mm a zzz', {
    timezone: _getClientTimezone(),
  })
}

function serializeDate(date) {
  return formatISO(date, { representation: 'date' }) //  formatted date string (YYYY-MM-dd)
}

function serializeTimeFromString(timeString) {
  // translates a string time like "13:30" to a standard time object:
  //     {hour: 13, minute: 30}
  // this function is intended to smooth over compatibility issues caused by our switch
  // from the former time format to the latter, but only in the short term. should
  // eventually be deprecated.

  return {
    hour:
      (parseInt(timeString.split(':')[0], 10) % 12) +
      (timeString.split(' ')[1] === 'PM' ? 12 : 0),
    minute: parseInt(timeString.split(':')[1], 10),
  }
}

function parseDate(date_) {
  if (!date_) {
    return date_
  }
  const date = new Date(date_)
  // Parsing a time-agnostic date means that we need to cancel out the browser
  // timezone that JS will automatically add
  return add(date, {
    minutes: date.getTimezoneOffset(),
  })
}

function formatUnixTimestamp(dateInMilliseconds, { showYear } = {}) {
  if (!dateInMilliseconds) {
    return dateInMilliseconds
  }

  if (!isInteger(dateInMilliseconds)) {
    throw new TypeError(
      `Got "${dateInMilliseconds}" for dateInMilliseconds, expected a UNIX timestamp`
    )
  }

  return showYear
    ? format(dateInMilliseconds, 'MMMM d, yyyy')
    : format(dateInMilliseconds, 'MMMM d')
}

export default {
  computed: {
    deltaOptions() {
      return deltaOptions.map((delta) => ({
        name: formatDelta(delta),
        value: delta,
      }))
    },
  },
  methods: {
    formatTime,
    formatDate,
    formatDelta,
    formatDynamicDate,
    formatDatetime,
    serializeDate,
    serializeTimeFromString,
    parseDate,
    formatUnixTimestamp,
  },
}
